<template>
  <section>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:visible', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">
            Criar Serviço
          </h5>

          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <b-form
          class="p-2"
          @submit.prevent="handleSubmit()"
          @reset.prevent="resetForm"
        >

          <b-form-group
            label="Nome"
            label-for="service-name"
          >
            <b-form-input
              id="service-name"
              v-model="formData.name"
              autofocus
              trim
              placeholder="Nome do Serviço"
            />
          </b-form-group>

          <b-form-group
            label="Imagem"
            label-for="service-image"
          >
            <b-form-input
              id="service-image"
              v-model="formData.image"
              autofocus
              trim
              placeholder="Imagem do Serviço"
            />
          </b-form-group>

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Limpar
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </section>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton
  },
  directives: {
    Ripple
  },
  model: {
    prop: 'visible',
    event: 'update:visible'
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    created_item: {},
    formData: {
      name: '',
      image: '',
      deactivated: 1
    }
  }),
  methods: {
    clearForm () {
      this.formData = {
        name: '',
        image: '',
        deactivated: 1
      }
    },
    async handleSubmit () {
      const url = '/admin/services'
      const params = {
        name: this.formData.name,
        image: this.formData.image,
        deactivated: this.formData.deactivated
      }

      this.$http.post(url, params)
        .then(res => {
          this.created_item = res.data
        })
        .finally(() => {
          this.$emit('updated', this.created_item)
          this.$emit('update:visible', false)

          this.$swal({
            title: 'Criado com sucesso!!',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-danger ml-1'
            },
            buttonsStyling: false
          })
        })
    }
  }
}
</script>
